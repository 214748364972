import React from 'react';
import './switch.css';

const Switch = ({ isOn, handleToggle }) => {
    return (
      <>
        <input
          checked={isOn}
          onChange={handleToggle}
          className="react-switch-checkbox hidden h-0 w-0"
          id={`react-switch-new`}
          type="checkbox"
        />
        <label
            //style={{ background: isOn && '#015CE4' }}
          className="react-switch-label"
          htmlFor={`react-switch-new`}
        >
          <span className={`react-switch-button`} />
        </label>
      </>
    );
  };
  
  export default Switch;