import React, { useState } from "react";
import { Link } from "gatsby";
import Arrow from "../../images/drop_up_grey.svg";
//import Signup from "../../components/buttons/frontegg-signup";
import { SignupButton } from "../buttons/frontegg";



//const isProduction = `${process.env.GATSBY_ACTIVE_ENV}` === "production";

const ck = <img src={"/checkmark-v2.svg"} alt="checked" />;



const FeaturesSection = ({ title, items, categories }) => {
  const [isOpen, setIsOpen] = useState(false);

  const header = [
    {
      title: items[0].title,
      type: "signup",
      label: "Try FREE",
      recommended: false,
    },
    {
      title: items[1].title,
      type: "signup",
      label: "Try FREE",
      recommended: true,
    },
    {
      title: items[2].title,
      type: "link",
      label: items[2].cta.title,
      uri: items[2].cta.uri.replace("internal:/", "/"),
      recommended: false,
    },
  ];

  return (
    <section className="py-6 lg:py-12 text-center hidden lg:block">
      <div className="container space-y-12">
        <FeaturesSection.Triger
          title={title}
          className="bg-[#F0F3F9] rounded-[60px] border-[#C7C9D9] text-[#5E718D] font-semibold max-w-[940px] mx-auto py-3"
          buttonClassName="complete-feature-list"
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />

        {isOpen && (
          <div className="max-w-[1108px] mx-auto space-y-12">
            {categories.map((category, index) => (
              <FeaturesSection.Section
                title={category.category}
                items={category.items}
                key={index}
                index={index}
                header={index === 0 ? header : []}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

FeaturesSection.HeaderRowFirst = ({ title, items, className }) => {
  const cells = items.map((item, index) => {
    if (item.type === "link")
      return (
        <div className="p-4 w-1/4 space-y-4" key={index}>
          <div>{item.title}</div>
          <Link
            className="text-sm border border-[#D7DFE9] text-[#5E718D] rounded-[8px] py-1.5 inline-block px-4  font-medium"
            to={item.uri}
          >
            {item.label}
          </Link>
        </div>
      );

    if (item.type === "signup")
      return (
        <div className="p-4 w-1/4 space-y-4" key={index}>
          <div>{item.title}</div>
          {/* <Signup
            title={item.label}
            className={
              "text-sm border border-[#D7DFE9]  rounded-[8px] py-1.5 inline-block px-4  font-medium " +
              (item.recommended ? "bg-[#2D3643] text-white" : " text-[#5E718D]")
            }
            type={"pricing"}
            name={item.title}
          /> */}
          <SignupButton
          
          className={
            "text-sm border border-[#D7DFE9]  rounded-[8px] py-1.5 inline-block px-4  font-medium " +
            (item.recommended ? "bg-[#2D3643] text-white" : " text-[#5E718D]")
          }
          type={"pricing"}
          name={item.title}
          >{item.label}</SignupButton>
        </div>
      );

    return <div className="p-4 w-1/4">-</div>;
  });

  return (
    <div className={className}>
      <div className="p-4 w-1/4 text-[28px] font-light ">{title}</div>
      {cells}
    </div>
  );
};

FeaturesSection.HeaderRow = ({ title, className }) => {
  return (
    <div className={className}>
      <div className="p-4 w-1/4 text-[28px] font-light ">{title}</div>
    </div>
  );
};

FeaturesSection.Section = ({ title, items, index, header }) => {
  const headerRow =
    index === 0 ? (
      <FeaturesSection.HeaderRowFirst
        className="flex flex-row items-center text-left text-[18px] font-semibold text-[#1C222B] border-b"
        items={header}
        title={title}
      />
    ) : (
      <FeaturesSection.HeaderRow
        title={title}
        className="flex flex-row items-center text-left text-[18px] font-semibold text-[#1C222B] border-b"
      />
    );

  return (
    <div>
      {headerRow}
      {items.map((item, index) => (
        <FeaturesSection.Row {...item} index={index} key={index} />
      ))}
    </div>
  );
};

FeaturesSection.Row = ({ title, startup, growth, enterprise, index }) => {
  return (
    <div
      key={index}
      className={
        "flex flex-row items-center text-left " +
        (index % 2 !== 0 ? "bg-[#FAFAFD]" : "bg-white")
      }
    >
      <div className="w-1/4 p-4 font-semibold text-[#2D3643]">{title}</div>
      <div className="w-1/4 p-4 ">{startup === "true" ? ck : startup}</div>
      <div className="w-1/4 p-4 ">{growth === "true" ? ck : growth}</div>
      <div className="w-1/4 p-4 ">
        {enterprise === "true" ? ck : enterprise}
      </div>
    </div>
  );
};
FeaturesSection.Triger = ({
  title,
  className,
  buttonClassName,
  isOpen,
  setIsOpen,
}) => {
  return (
    <h3 className="bg-[#F0F3F9] rounded-[60px] border-[#C7C9D9] text-[#5E718D] font-semibold max-w-[940px] mx-auto py-3">
      <button
        className={"space-x-2 text-lg " + buttonClassName}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{title}</span>
        <span>
          <img
            src={Arrow}
            alt="arrow"
            className={
              "inline-block transition " + (!isOpen ? " " : "rotate-180 ")
            }
          />
        </span>
      </button>
    </h3>
  );
};


export default FeaturesSection