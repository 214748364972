import React, { useState } from "react";
import {  graphql } from "gatsby";
import Layout from "../components/layout";
import getMetatags from "../util/getMetatags";
import Seo from "../components/Seo";
import { pipesToSpan } from "../util/common";
//import Arrow from "../images/drop_up_grey.svg";
import Switch from "../components/buttons/switch";
import {SignupButton}  from "../components/buttons/frontegg";
import PlansSection from "../components/pricing/plans";
import FeaturesSection from "../components/pricing/features";
import ParagraphFaq from "../components/paragraphs/faq";
import ParagraphGenericBlock from "../components/paragraphs/generic-block"

//const isProduction = `${process.env.GATSBY_ACTIVE_ENV}` === "production";

//const ck = <img src={"/checkmark-v2.svg"} alt="checked" />;

function PricingPage({ data: { node, terms }, path, ...props }) {
  const { title2, description } = node;

  const { plans, features, faq, bottom } = node.relationships;

  //console.log(plans);
  //console.log(features);
  const seo = getMetatags(
    node.metatag,
    node?.relationships?.OGImage?.localFile?.publicURL
  );

  const [isAnnual, setIsAnnual] = useState(true);

  const categories = terms.nodes.map((term) => ({
    category: term.name,
    items: features.filter(
      (item) => item.relationships.category.id === term.id
    ),
  }));

  //console.log(categories);

  return (
    <Layout showCta={false} isMarketplace={true}>
      
      <Seo {...seo} />
      <div className="h-[70px]"></div>

      <div className= " bg-[url('/background/semantic-layer-hero-bckg.png')] bg-cover">
        
          <Hero
            title={title2}
            description={description}
            isAnnual={isAnnual}
            setIsAnnual={setIsAnnual}
          />
        
        
          <PlansSection items={plans} isAnnual={isAnnual} features={features} />
        

      </div>
      <FeaturesSection
        items={plans}
        features={features}
        title="Complete feature list"
        categories={categories}
      />

      <Component data={faq} context="pricing"></Component>

      <Component data={bottom} context="pricing">
        {/* <Signup
          title={bottom.cta.title}
          className="border py-2.5 px-5 text-white rounded-full inline-block lg:mt-4  text-base font-medium bg-[#455468] rounded-[24px]"
          type={"pricing"}
          name={"cta"}
        /> */}
        <SignupButton
        className="border py-2.5 px-5 text-white rounded-full inline-block lg:mt-4  text-base font-medium bg-[#455468] rounded-[24px]"
        type={"pricing"}
        name={"cta"}
        >
        {bottom.cta.title}
        </SignupButton>
      </Component>
    </Layout>
  );
}

const Hero = ({ title, isAnnual, setIsAnnual }) => {
  const t = pipesToSpan(title, "font-medium");
  return (
    <section className="pt-12 ">
      <div className="container ">
        <div className="space-y-4 lg:pr-6 text-center">
          <h1
            className=" text-[35px] lg:text-[70px] font-light leading-[120%] tracking-[-2.1px] font-readexpro"
            dangerouslySetInnerHTML={{ __html: t }}
          ></h1>
{/* 
<div className="w-[480px] text-center mx-auto">
<div id="knonk-button-1" ></div>

</div> */}


<div className="md:w-[480px] text-center mx-auto">


</div>



          <div className="block pb-0 pt-6 mx-auto">
            <div className="flex flex-row gap-x-4  items-center  justify-center text-xs font-light text-[#0f0f37]">
              <div className="">{"Monthly"}</div>
              <div className="">
                <Switch
                  isOn={isAnnual}
                  handleToggle={(e) => setIsAnnual(e.target.checked)}
                />
              </div>
              <div className="">{"Annual billing"}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// import React from "react";
// import TaxonomyTermIntegrationCategories from "./taxonomy/integration-categories";
// // import {
// //   BlockContentBlogSideBlock,
// //   BlockContentBlogPostSideWithoutImage,
// // } from "./blocks/content";
// import BlockContentSectionContent from "./blocks/content-section";
// import ParagraphGenericBlock from "./paragraphs/generic-block";
// import SectionCardsAccross from "./blocks/section-cards-across";
// 

const Component = ({ data, context, ...props }) => {
  //console.log( data )
  let comp = "";

  switch (data.__typename) {
    
    case "paragraph__generic_block":
      comp = <ParagraphGenericBlock data={data} context={context} {...props} />;
      break;

    case "paragraph__faq":
      comp = <ParagraphFaq data={data} context={context} {...props} />;
      break;

    default:
      comp = <pre>{JSON.stringify(data, null, 3)}</pre>;
      break;
  }

  return <>{comp}</>;
};



export default PricingPage;

export const query = graphql`
  query pricing($id: String!) {
    node: nodePricing(id: { eq: $id }) {
      ...NodePricing
    }
    terms: allTaxonomyTermPricing(sort: { fields: weight }) {
      nodes {
        __typename
        name
        id
      }
    }
  }
`;
