import React, { useState } from "react";
import { Link } from "gatsby";
import Arrow from "../../images/drop_up_grey.svg";
import { SignupButton } from "../buttons/frontegg";

const isProduction = `${process.env.GATSBY_ACTIVE_ENV}` === "production";

//const ck = <img src={"/checkmark-v2.svg"} alt="checked" />;

const PlansSection = ({ items, isAnnual, features }) => {
  const [isOpen, setIsOpen] = useState(false);

  const all = {
    0: features
      .map((f) => ({ title: f.title, point: f.startup }))
      .filter((f) => f.point),
    1: features
      .map((f) => ({ title: f.title, point: f.growth }))
      .filter((f) => f.point),
    2: features
      .map((f) => ({ title: f.title, point: f.enterprise }))
      .filter((f) => f.point),
  };

  //console.log(all);
  //const isSSR = typeof window === "undefined";
  const cards = items
    .map((item) => ({
      ...item,
      cta: {
        uri: item.cta.uri.replace("internal:/", "/"),
        title: item.cta.title,
      },
    }))
    .map((item, index) => (
      <PlanCard
        {...item}
        key={index}
        index={index}
        isAnnual={isAnnual}
        isOpen={isOpen}
        all={all}
        setIsOpen={setIsOpen}
        recommended={index === 1}
      />
    ));
  return (
    <section className="mx-4">
      <div className="container lg:max-w-[944px] px-0  mt-12  ">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-12 lg:gap-x-6">
          {cards}
        </div>
      </div>
    </section>
  );
};

const PlanCard = ({
  title,
  isAnnual,
  subtitle,
  subheader,
  description,
  points,
  index,
  cta,
  isOpen,
  all,
  setIsOpen,
  recommended,
  ...props
}) => {
  const points2 = [...points];
  const first = points2.shift();

  const { addon } = props;
  if (!isProduction) {
    console.log(props);
  }

  return (
    <div
      className={
        "rounded-[12px] " +
        (recommended ? "p-[2px] bg-[#4A72FF] " : "mb-[-2px]")
      }
    >
      <div className="h-[36px] flex flex-col items-center justify-center">
        {recommended && (
          <div className="text-white text-center  text-sm font-medium ">
            Recommended
          </div>
        )}
      </div>
      <div className="border p-6 rounded-[12px] border-[#D7DFE9] bg-white  text-[#2D3643] space-y-6">
        <div className="border-b space-y-6 lg:h-[200px] ">
          <div className="text-base  font-semibold ">{title}</div>

          <PlanCard.Title
            title={isAnnual ? subtitle : subheader}
            className="text-[48px] font-bold"
          />

          <div className="text-sm  ">{description}</div>
        </div>
        <div className="hidden lg:block space-y-5 text-[13px]">
          <div className="text-left font-semibold  ">{first}</div>
          <ul className="text-left list-none space-y-3   font-medium">
            {points2.map((point, i) => (
              <li
                key={i}
                style={{
                  background: "url('/checkmark-v2.svg') no-repeat left ",
                }}
                className={"pl-4 "}
              >
                <span className="ml-4 leading-[120%]">{point}</span>
              </li>
            ))}
          </ul>

          {!isProduction && addon.length && (
            <div
              className={
                " px-3 py-2 rounded-[6px] space-y-2.5 text-[13px] leading-[26px] tracking-[-0.02em] font-medium text-[#3D434C] " +
                (index === 1 ? "bg-[#E8EDFF]" : "bg-[#F4F5F6]")
              }
            >
              <div className="font-semibold">Add-ons for {title} plan</div>
              <ul className="font-medium min-h-[56px]">
                {addon.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="pt-6 hidden lg:block text-center text-[#5E718D] text-base">
          {index < 2 && (
            <>
              {/* <Signup
              title={cta.title}
              className={
                "border border-[#D7DFE9] rounded-[8px] py-2.5 inline-block w-full  font-semibold " +
                (recommended ? "bg-[#2D3643] text-white" : "")
              }
              type={"pricing"}
              name={title}
            /> */}
              <SignupButton
                className={
                  "border border-[#D7DFE9] rounded-[8px] py-2.5 inline-block w-full  font-semibold " +
                  (recommended ? "bg-[#2D3643] text-white" : "")
                }
                type={"pricing"}
                name={title}
              >
                {cta.title}
              </SignupButton>
            </>
          )}

          {index >= 2 && (
            <>
            <div>
            <Link
              className="border rounded-[8px] py-2.5 inline-block w-full  font-semibold "
              to={cta.uri}
              id={"button-" + title}
            >
              {cta.title}
            </Link>
            </div>
            
            </>
          )}
        </div>

        <div className="pb-6 border-b  lg:hidden">
          <Link
            className="border rounded-[12px] p-4 inline-block --w-full  text-[#5E718D] font-semibold text-xl"
            to={cta.uri}
          >
            {cta.title}
          </Link>
        </div>

        <div className=" lg:hidden">
          <ul className="text-left list-none space-y-5 text-[#555770]">
            {points.map((point, i) => (
              <li
                key={i}
                style={{
                  background: "url('/checkmark-v2.svg') no-repeat left",
                }}
                className="pl-10"
              >
                <span className="">{point}</span>
              </li>
            ))}
          </ul>

          {isOpen && (
            <div className="">
              <div className="text-left py-4 pl-10 text-grey">
                More features
              </div>
              <ul className="text-left list-none space-y-5 text-[#555770]">
                {all[index].map((point, i) => (
                  <li
                    key={i}
                    style={{
                      background: "url('/checkmark-v2.svg') no-repeat left 5px",
                    }}
                    className="pl-10"
                  >
                    <span className="">
                      {point.point !== "true" ? point.point : ""} {point.title}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <button
            className="space-x-2 mt-6 text-[#555770] text-lg"
            onClick={() => setIsOpen(!isOpen)}
          >
            {!isOpen && <span>{"See all features"}</span>}
            {isOpen && <span>{"Show less"}</span>}
            <span>
              <img
                src={Arrow}
                alt="arrow"
                className={
                  "inline-block transition " + (isOpen ? " " : "rotate-180 ")
                }
              />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

PlanCard.Title = ({ title, className }) => {
  const pattern = "/mo";
  const t2 = title.replace(
    pattern,
    '<span class="text-base font-normal ml-2">/ month</span>'
  );

  return <div className={className} dangerouslySetInnerHTML={{ __html: t2 }} />;
};

export default PlansSection;
