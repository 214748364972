import React from "react";
//import { Link } from "gatsby";
import { SignupButton } from "../buttons/frontegg";
import ParagraphGenericBlockAi from "./generic-block-ai";


const ParagraphGenericBlock = ({ data, context, onClick, ...props }) => {
  //console.log(data);
  //console.log(context);

  if (context === "ai") {
    return <ParagraphGenericBlockAi data={data} {...props} />;
  }

  const { children } = props;

  const { title, description, description_html, cta, label } = data;
  const image = {
    src: data?.relationships?.image?.localFile?.publicURL,
    alt: data?.image?.alt,
  };

  if (context === "marketplace-libraray") {
    return (
      <div className="bg-[#EDFBFE] py-[35px] px-[30px] flex flex-row justify-between eounded-[13px]">
        <div>
          <h3 className="font-readexpro text-2xl leading-[26px] tracking-[-1px] text-[#3D4A5C]">
            {title}
          </h3>
          <div className="mt-3 mb-4 text-sm text-[#3D4A5C] leading-[20px] lg:w-[456px]">
            {description}
          </div>
          <div>
            <button
              onClick={onClick}
              className="rounded-[13px] bg-[#455468] text-white text-xs leading-[20px] py-1 px-5 font-medium"
            >
              {cta.title}
            </button>
          </div>
        </div>
        <div className="w-[244px] hidden lg:block relative">
          <div className="absolute top-0 right-0 w-[328px] h-[173px]">
            <img src={image.src} alt={image.alt} className="z-10" />
          </div>
        </div>
      </div>
    );
  }

  if (context === "pricing") {
    return (
      <section className="py-12  text-[#3D4A5C] ">
        <div className="container max-w-[940px] px-0 ">
          <div className=" bg-[#EDFBFE] flex flex-col lg:flex-row  justify-between items-center  rounded-[13px] px-8 py-10">
            <div className="lg:w-7/12  text-center lg:text-left space-y-6 lg:space-y-5">
              <div className="text-[20px] lg:text-[28px] font-readexpro  ">
                {label}
              </div>

              <div
                className="text-base lg:text-lg leading-[140%]"
                dangerouslySetInnerHTML={{ __html: title }}
              ></div>

              <div>
                {/* <Signup
                  title={cta.title}
                  className="border py-2.5 px-5 text-white rounded-full inline-block lg:mt-4  text-base font-medium bg-[#455468] rounded-[24px]"
                  type={"pricing"}
                  name={"cta"}
                /> */}
                {children}
              </div>
            </div>
            <div className="lg:w-5/12 text-right">
              <img
                src={image.src}
                className="inline-block"
                alt={`${image?.alt}`}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
  return (
    <div className="bg-[#F9F8F7] py-6 md:py-[40px] md:pb-[50px] px-4 md:px-[50px] flex flex-row justify-between  text-[#3D434C] rounded-[20px]">
      <div>
        <h3 className="text-[36px] leading-[40px] tracking-[-0.04em] font-medium max-w-[660px]">
          {title}
        </h3>

        {description_html && (
          <div
            className="mt-5 mb-[30px] text-lg  leading-[29px] lg:max-w-[666px]"
            dangerouslySetInnerHTML={{ __html: description_html.processed }}
          />
        )}

        <div>
          <SignupButton
            className="bg-[#3D434C] text-white font-medium leading-[20px] inline-block px-5 py-3 rounded-[8px]"
            type="custom_metric"
            integration={props.integration}
            name=""
          >
            {cta.title}
          </SignupButton>
        </div>
      </div>
      <div className="--w-[244px] hidden lg:block relative ">
        <div className="absolute top-[-60px] right-[-50px] w-[455px] h-[442px] ">
          <img src={image.src} alt={image.alt} className="z-10 " />
        </div>
      </div>
    </div>
  );
};


export default ParagraphGenericBlock;
